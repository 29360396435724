import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

/**
 * A Stimulus controller for handling form interactions, including debounced submissions,
 * sorting, API key regeneration, and preserving scroll position during form submissions.
 *
 * @extends Controller
 */
export default class extends Controller {
  /**
   * Targets defined for this controller.
   * @type {string[]}
   */
  static targets = ['search', 'sortAnswer'];

  /**
   * Methods that are debounced.
   * @type {string[]}
   */
  static debounces = ['submit'];

  /**
   * Values defined for this controller.
   * @type {{ disableWhileSubmitting: Boolean }}
   */
  static values = {
    disableWhileSubmitting: Boolean,
  };

  /**
   * Initializes the controller. Restores the scroll position if it was previously saved.
   */
  initialize() {
    if (window.scrollYPosition > 0) {
      window.scrollTo(0, window.scrollYPosition);
      window.scrollYPosition = 0;
    }
  }

  /**
   * Connects the controller. Sets up debouncing and focuses the search input if it has the `autofocus` attribute.
   */
  connect() {
    useDebounce(this, { wait: 300 });
    if (this.hasSearchTarget) {
      setTimeout(() => {
        this.searchTarget.hasAttribute('autofocus') &&
          this.searchTarget.focus();
      }, 100);
    }
  }

  /**
   * Handles changes to the sort answer target. Updates the target value and submits the form.
   *
   * @param {Event} event - The change event triggered by the target element.
   */
  sortAnswerChanged(event) {
    const selectedValue = event.target.value;
    this.sortAnswerTarget.value = selectedValue;
    this.element.requestSubmit();
  }

  /**
   * Regenerates the API key by setting a field's value to true and submitting the form.
   */
  regenerateApiKey() {
    document.getElementById('regenerate_api_key_field').value = 'true';
    const form = document.getElementById('setting_form');
    form.submit();
  }

  /**
   * Submits the form with an additional parameter. Adds a hidden input to the form and removes it after submission.
   *
   * @param {Event} event - The event triggering the submission.
   */
  async submitWithParam(event) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = event.target.getAttribute('data-param');
    input.value = 'true';
    this.element.appendChild(input);

    const onSubmitEnd = (turboEvent) => {
      if (turboEvent.target === this.element) {
        this.element.removeChild(input);
        this.element.removeEventListener('turbo:submit-end', onSubmitEnd);
      }
    };

    this.element.addEventListener('turbo:submit-end', onSubmitEnd);

    this.submit(event);
  }

  /**
   * Submits the form. Preserves scroll position and disables form fields if specified.
   *
   * @param {Event} event - The event triggering the submission.
   */
  submit(event) {
    if (this.element.hasAttribute('data-turbo-preserve-scroll')) {
      window.scrollYPosition = window.scrollY;
    }
    if (this.disableWhileSubmittingValue) {
      this.element.style.pointerEvents = 'none';
      event.target.appendChild(this.spinner);
    }

    this.element.requestSubmit();
  }

  /**
   * Clears the form by navigating to its action URL.
   */
  clear() {
    Turbo.visit(this.element.action);
  }

  /**
   * Creates and returns a spinner element to indicate loading.
   *
   * @returns {HTMLElement} The spinner element.
   */
  get spinner() {
    const spinner = document.createElement('rmv-spinner');
    spinner.slot = 'suffix';
    return spinner;
  }
}
