import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'input'];

  toggle() {
    if (!this.hasSelectTarget || !this.hasInputTarget) return;

    const newDisable =
      this.selectTarget.value === 'Auto' ||
      this.selectTarget.value === 'false' ||
      this.selectTarget.value === '0';
    console.log(this.selectTarget.value);
    console.log(newDisable);
    this.inputTarget.disabled = newDisable;
    if (newDisable) {
      if (this.inputTarget.dataset.disableToggleSetsValueTo) {
        this.inputTarget.value =
          this.inputTarget.dataset.disableToggleSetsValueTo;
      } else {
        this.inputTarget.value = '';
      }
    }
  }
}
