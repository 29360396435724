import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';
import turboFetch from 'lib/turbo_fetch';

export default class extends Controller {
  static targets = ['search', 'blob'];
  static values = { url: String };
  static debounces = ['search'];

  connect() {
    this.controller = null;
    useDebounce(this, { wait: 300 });
  }

  search() {
    if (this.controller) {
      this.controller.abort();
    }

    this.controller = new AbortController();
    const signal = this.controller.signal;
    const query = this.searchTarget.value;
    const url = new URL(this.urlValue, window.location.origin);
    url.searchParams.set('query', query);

    // Reset offset when searching
    const lazyLoadElement = document.querySelector('[data-controller="lazy-load"]');
    if (lazyLoadElement) {
      lazyLoadElement.dataset.lazyLoadOffsetValue = "0";
      lazyLoadElement.dataset.lazyLoadUrlValue = url.toString();
    }

    turboFetch(url.toString(), 'GET', '', signal);
  }
}
