import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';
import { get, post, patch } from '@rails/request.js';
/**
 * Lazy Load Controller for handling dynamic content loading.
 * This controller implements infinite scrolling functionality with optional scroll-based loading.
 *
 * @class
 * @extends Controller
 */
export default class extends Controller {
  /** @type {string[]} Methods to be debounced */
  static debounces = ['onScroll'];
  /** @type {Object} Controller values configuration */
  static values = {
    /** @type {number} Number of items to load per request */
    limit: { type: Number, default: 5 },
    /** @type {number} Current offset for pagination */
    offset: { type: Number, default: 0 },
    /** @type {string} URL to fetch data from */
    url: { type: String, default: window.location.href },
    /** @type {number} Total number of items available */
    totalCount: { type: Number, default: 0 },
    /** @type {boolean} Whether scroll-based loading is enabled */
    scrollEnabled: { type: Boolean, default: true },
    /** @type {string} HTTP method to use for requests */
    method: { type: String, default: 'get' },
  };
  /**
   * Initializes the controller when connected.
   * Sets up debouncing, binds methods, and attaches scroll listener if enabled.
   */
  connect() {
    useDebounce(this, { wait: 300 });
    this.fetchData = this.fetchData.bind(this);
    this.onScroll = this.onScroll.bind(this);
    if (this.scrollEnabledValue) {
      this.element.addEventListener('scroll', this.onScroll);
    }
    this.loading = false;
  }
  /**
   * Cleans up event listeners when controller is disconnected.
   */
  disconnect() {
    this.element.removeEventListener('scroll', this.onScroll);
  }
  /**
   * Fetches more data from the server.
   * Updates offset and appends new content to the element.
   * @returns {Promise<void>}
   */
  fetchData() {
    this.loading = true;
    const url = new URL(this.urlValue);
    this.offsetValue = this.offsetValue + this.limitValue;

    url.searchParams.set('offset', this.offsetValue);
    url.searchParams.set('limit', this.limitValue);

    const method = this.methodValue.toLowerCase();
    const requestMethod = { get, post, patch }[method];

    requestMethod(url.toString(), { responseKind: 'turbo-stream' })
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.error('Error loading more content:', error);
        this.loading = false;
      });
  }
  /**
   * Handles scroll events to trigger lazy loading.
   * Only triggers when scrolled to bottom and more items are available.
   */
  onScroll() {
    /**
     * Visual representation of scroll positions:
     * +------------------+
     * |                  | ← Top of viewport (scrollTop)
     * |   Visible Area   |
     * |                  | ← clientHeight (viewport height)
     * +------------------+ ← scrollTop + clientHeight (bottom of viewport)
     * |   Hidden Area    |
     * +------------------+ ← scrollHeight (total height)
     *
     * When the bottom of the viewport reaches the total height (or is within 1 pixel),
     * the expression returns true, typically used to trigger loading more content (infinite scroll).
     */
    const hasReachedBottom =
      Math.abs(
        this.element.scrollTop +
          this.element.clientHeight -
          this.element.scrollHeight
      ) <= 1;
    const hasMoreItems =
      this.offsetValue + this.limitValue < this.totalCountValue;
    if (!this.loading && hasMoreItems && hasReachedBottom) {
      this.fetchData();
    }
  }
}
