import { Controller } from '@hotwired/stimulus';

/**
 * Represents a modal controller.
 * @class
 * @extends Controller
 */
export default class extends Controller {
  /**
   * The targets property specifies the list of target elements for the modal controller.
   * These targets are used to reference specific elements in the DOM.
   *
   * @type {string[]}
   */
  static targets = ['hiddenField', 'folder', 'confirmation', 'foldersList'];

  /**
   * Connects the modal controller to the DOM element.
   */
  connect() {
    this.dynamicModal = this.element.hasAttribute('data-dynamic');
  }

  /**
   * Initializes the modal controller.
   */
  initialize() {
    if (!this.element.hasAttribute('hidden')) {
      this.element.click();
    }
  }

  /**
   * This method is ONLY used for dynamic modals (such as the preview loader modal)
   * We append the dynamic modal to the turbo-frame in the <body> which is meant for displaying modals
   * @param {Event} e - The event object.
   */
  showModal(e) {
    e.preventDefault();
    e.stopPropagation();
    // Access the <rmv-modal> element inside the parent element
    const modal = this.element.querySelector('rmv-modal');
    modal.open = true;
  }

  /**
   * Hides the dynamic modal by appending it back to its original parent and removing the 'open' attribute.
   */
  hideDynamicModal() {
    // Access the <rmv-modal> element
    const modal = this.element;
    modal.open = false;
  }

  /**
   * Hides the modal.
   * This method can be used for BOTH turbo-frame modals and dynamic modals
   */
  hideModal() {
    if (this.dynamicModal) {
      this.hideDynamicModal();
    } else {
      const frame = this.element.closest('turbo-frame[id="modal"]');
      //remove the src
      frame.removeAttribute('src');
      // Remove each child element inside the turbo-frame
      Array.from(frame.children).forEach((childElement) =>
        childElement.remove()
      );
    }
  }

  /**
   * Closes the modal when the Escape key is pressed.
   *
   * @param {KeyboardEvent} e - The keyboard event object.
   */
  closeWithKeyboard(e) {
    if (e.code == 'Escape') {
      this.hideModal();
    }
  }

  /**
   * Closes the background of the modal if the event target is outside the <rmv-card> of the modal.
   * @param {Event} e - The event object.
   */
  closeBackground(e) {
    const modal = this.element;
    /**
     * When the confirmation modal is opened inside another modal, we want to prevent the main modal from closing
     * when the user clicks inside the confirmation modal.
     */
    const confirmationModal = document.querySelector(
      'rmv-modal[id="confirmation-modal"]'
    );

    if (modal) {
      if (modal.tagName.toLowerCase() === 'rmv-modal') {
        const rmvCard = modal.querySelector('rmv-card');
        const confirmationModalCard =
          confirmationModal.querySelector('rmv-card');

        if (!rmvCard) {
          console.warn(
            'rmv-modal must contain a rmv-card to indicate the modal content'
          );
        }

        // If the event target is the rmv-card, do nothing (keep modal open)
        if (
          (e && rmvCard?.contains(e.target)) ||
          confirmationModalCard?.contains(e.target)
        ) {
          return;
        }
        this.hideModal();
      }
    }
  }

  /**
   * Handles the selection of a folder.
   *
   * @param {Event} e - The event object triggered by the folder selection.
   */
  selectFolder(e) {
    const folderId = e.currentTarget.getAttribute('data-folder-id');
    // This line
    this.hiddenFieldTarget.value = folderId;
    this.folderTargets.forEach((folder) => {
      folder.classList.remove('selected');
    });
    e.currentTarget.classList.add('selected');
  }
}
