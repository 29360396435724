import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

/**
 * Represents a widget controller.
 * @class
 * @extends Controller
 */
export default class extends Controller {
  /** @type {string[]} - Target elements for the reset button */
  static targets = ['reset'];

  /** @type {Object} - Value definitions for the controller
   * @property {string} completedUrl - URL to call when the widget is completed
   * @property {boolean} isDirty - Indicates if the form has ever been changed
   */
  static values = {
    completedUrl: String,
    isDirty: Boolean,
  };

  /**
   * Initializes the controller by binding event handlers.
   * Called when the controller is first instantiated.
   * @function
   */
  initialize() {
    this.handleCompletedEvent = this.handleCompletedEvent.bind(this);
  }

  /**
   * Stimulus lifecycle callback that runs when isDirtyValue changes.
   * Updates the reset button's disabled state based on form changes.
   * @function
   */
  isDirtyValueChanged() {
    if (this.hasResetTarget) {
      this.resetTarget.disabled = !this.isDirtyValue;
    }
  }

  /**
   * Stimulus lifecycle callback that runs when the controller connects.
   * Sets up event listeners for:
   * - Widget completion events
   * - Form change events to track dirty state
   * @function
   */
  connect() {
    document.addEventListener(
      'rmv-widget:completed',
      this.handleCompletedEvent
    );

    // Get the rmv-widget element
    const widget = this.element.querySelector('rmv-widget');
    if (widget) {
      // Wait for the custom element to be defined
      customElements.whenDefined('rmv-widget').then(() => {
        const form = widget.formElement;

        form.addEventListener('change', () => {
          this.isDirtyValue = true;
        });

        form.addEventListener('input', () => {
          this.isDirtyValue = true;
        });
      });
    }
  }

  /**
   * Stimulus lifecycle callback that runs when the controller disconnects.
   * Cleans up event listeners to prevent memory leaks.
   * @function
   */
  disconnect() {
    document.removeEventListener(
      'rmv-widget:completed',
      this.handleCompletedEvent
    );
  }

  /**
   * Event handler for the 'rmv-widget:completed' event.
   * Makes a GET request to the completedUrl when the widget is completed.
   * @param {CustomEvent} e - The completed event from the widget
   * @function
   */
  handleCompletedEvent(e) {
    get(this.completedUrlValue, {
      responseKind: 'turbo-stream',
    });
  }
}
