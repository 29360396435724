function setTimezone() {
  var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var expires = new Date();

  expires.setTime(expires.getTime() + 60 * 60 * 24);
  expires = expires.toUTCString();

  document.cookie = 'timezone=' + timezone + ';expires=' + expires + '; Path=/';
}

setTimezone();

document.addEventListener('turbo:load', setTimezone);
document.addEventListener('turbo:frame-load', setTimezone);
