import { Controller } from '@hotwired/stimulus';

/**
 * @class RowFieldsValidatorController
 * @extends Controller
 *
 * This Stimulus controller manages the validation of form fields within a row.
 * It ensures that all required fields are filled before enabling the "Add Row" button.
 */
export default class extends Controller {
  static targets = ['form', 'addRowButton', 'excludedField'];

  /**
   * Called when the controller is connected to the DOM.
   * Initializes event handlers for select fields.
   */
  connect() {
    this.selectFocused = false;
    this.setSelectBlurHandler = this.setSelectBlur.bind(this);
  }

  /**
   * Called when the controller is disconnected from the DOM.
   * Removes event listeners from select fields.
   */
  disconnect() {
    this.removeSelectEventListeners();
  }

  /**
   * Adds event listeners for blur events on select fields.
   */
  setSelectEventListeners() {
    const selectFields = this.formTarget.querySelectorAll('rmv-select');
    selectFields.forEach((select, index) => {
      if (!select.eventId) {
        select.eventId = `select-id-${index}`;
      }
      const eventId = select.eventId;
      this.element.addEventListener(
        `rmv-select:blur:${eventId}`,
        this.setSelectBlurHandler
      );
    });
  }

  /**
   * Removes event listeners for blur events from select fields.
   */
  removeSelectEventListeners() {
    const selectFields = this.formTarget.querySelectorAll('rmv-select');
    selectFields.forEach((select, index) => {
      if (!select.eventId) {
        select.eventId = `select-id-${index}`;
      }
      const eventId = select.eventId;
      this.element.removeEventListener(
        `rmv-select:blur:${eventId}`,
        this.setSelectBlurHandler
      );
    });
  }

  /**
   * Sets the selectFocused flag to false and checks the fields when a select field loses focus.
   */
  setSelectBlur() {
    this.selectFocused = false;
    this.checkFields();
  }

  /**
   * Checks if all required fields are filled and enables/disables the "Add Row" button accordingly.
   * Optionally shows error states for empty fields.
   *
   * @param {boolean} [showErrors=true] - Whether to show error states for empty fields.
   */
  checkFields(showErrors = true) {
    this.setSelectEventListeners();
    // The delay is needed to support the 'Remove' row. If there is no delay, the fields will still be there when the checkFields function is called.
    setTimeout(() => {
      const fieldsArray = Array.from(
        this.formTarget.querySelectorAll('rmv-textarea, rmv-input, rmv-select')
      );

      // Create a new array excluding the excludedFieldTargets
      const filteredFieldsArray = fieldsArray.filter(
        (field) => !this.excludedFieldTargets.includes(field)
      );

      const allFilled = filteredFieldsArray.every((field) => {
        if (field.tagName.toLowerCase() === 'rmv-select') {
          return field.value.trim() !== '' && field.value !== 'false';
        }
        return field.value.trim() !== '';
      });

      this.addRowButtonTargets.forEach((button) => {
        button.disabled = !allFilled;
      });

      if (showErrors) {
        let row = fieldsArray
          .find((field) => field === document.activeElement)
          ?.closest('rmv-row[data-row-id]');

        // Set the field to an error state if it's empty and not the current focused row
        filteredFieldsArray.forEach((field) => {
          const isCurrentRow = row === field.closest('rmv-row[data-row-id]');
          const isEmpty =
            field.value.trim() === '' ||
            (field.tagName.toLowerCase() === 'rmv-select' &&
              field.value === 'false');

          // Set the error based on the conditions
          field.error = isEmpty && !isCurrentRow;
        });
      }
    }, 50);
  }

  /**
   * Disables the "Add Row" button and focuses the first empty field in the form.
   */
  disableAddRowButton() {
    this.addRowButtonTargets.forEach((button) => {
      button.disabled = true;
    });

    // Auto focus the first field in the form after clicking the 'Add row' button
    this.focusFirstElement();
  }

  /**
   * Focuses the first empty field in the form.
   */
  focusFirstElement() {
    const fields = Array.from(
      this.formTarget.querySelectorAll('rmv-textarea, rmv-input')
    );

    // Filter fields to include only those with no value
    const emptyFields = fields.filter((field) => !field.value.trim());

    // Focus on the first empty field, if it exists
    emptyFields[0]?.focus();
  }
}
